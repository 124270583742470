
import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { UFliterals } from "@/utils/literals";
import { ITip } from "@/schemas/ITip";

@Component({
  name: "TipsOverviewPage",
})
export default class TipsOverviewPage extends Vue {
  // The current session identifier
  @Prop({ required: true, type: String })
  sessionId!: string;

  private tips: string[] = [];
  displayNext = false;
  displayStart = false;
  lastDisplayedIndex = 0;
  timeoutID: number | null = null;
  timeout = 5000;
  fullyLoaded = false;

  created(): void {
    const instanciateTip = (): boolean => {
      let session = this.$store.state.session.session;
      if (session) {
        let allowTips = session.setting?.tip_overview;
        if (allowTips) {
          if (session.setting?.overview_tips) {
            this.tips =
              session.setting?.overview_tips.tips?.map(
                (tip: ITip) => tip.content
              ) || [];
            this.timeoutID = setTimeout(() => {
              this.displayNext = true;
            }, this.timeout);
          }
        }
        return !!this.tips.length;
      }
      return false;
    };
    let tipsInstanciated = instanciateTip();
    if (!tipsInstanciated) {
      this.$store.dispatch("session/fetchSession", {
        sessionId: this.sessionId,
        next: () => {
          tipsInstanciated = instanciateTip();
          if (!tipsInstanciated) {
            this.redirectToGroupSession();
            return;
          }
          this.fullyLoaded = true;
        },
      });
    }
    this.fullyLoaded = true;
  }

  onClickNext(): void {
    this.displayNext &&= false;
    this.lastDisplayedIndex += 1;
    if (this.lastDisplayedIndex < this.tips.length - 1) {
      this.timeoutID = setTimeout(() => {
        this.displayNext = true;
      }, this.timeout);
      return;
    }
    this.timeoutID = setTimeout(() => {
      this.displayStart = true;
    }, this.timeout);
  }

  get displayedTip(): string[] {
    return this.tips.filter((tip, index) => this.lastDisplayedIndex >= index);
  }

  get displayNextButton(): boolean {
    return this.displayNext && this.lastDisplayedIndex < this.tips.length - 1;
  }

  redirectToGroupSession(): void {
    this.$router.push({
      name: "groupSession",
      params: {
        sessionId: this.sessionId,
      },
    });
  }

  beforeRouteLeave(): void {
    console.log("beforeRouteLeave")
    if (this.timeoutID) clearTimeout(this.timeoutID);
  }

  beforeDestroy(): void {
    console.log("beforeDestroy")
    // if (this.timeoutID) clearTimeout(this.timeoutID);
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }
}
